<script>
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'ewallet-services',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isShow(widget = {}) {
      const {
        enableWidget = true,
        showOnDesktop = true,
        showOnMobile = true,
      } = widget

      const isCorrectScreenSize =
        (showOnDesktop && !ui.mobile) || (showOnMobile && ui.mobile)

      return isCorrectScreenSize && enableWidget
    },
  },
}
</script>

<template>
  <div class="flex w-full flex-col gap-y-3">
    <template v-for="(item, index) in items">
      <base-action
        v-if="isShow(item)"
        v-bind="item.action"
        :key="index"
        class="block"
        :type="item.action ? item.action.type : 'none'"
      >
        <template #entry>
          <div
            class="flex w-full shrink-0 grow items-center gap-x-4 rounded-md border px-4 py-3 transition duration-150 hover:shadow"
            :style="{
              backgroundColor: item.bgColor || '#FFFFFF',
              color: item.textColor || '#1F2937',
            }"
          >
            <base-icon
              v-if="item.icon"
              :svg="item.icon"
              :size="40"
              class="shrink-0 text-primary"
              :style="{
                color: item.iconColor,
              }"
            />
            <span class="text-sm font-bold">
              {{ item.label }}
            </span>
            <base-icon
              svg="heroicons/outline/chevron-right"
              :size="24"
              class="ml-auto shrink-0"
            />
          </div>
        </template>
      </base-action>
    </template>
  </div>
</template>
